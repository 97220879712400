import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { Spin, Row, Col } from "antd";
import { useEffect, useRef } from "react";
import { useAuth } from "../../context/authContext";
import AuthView from "./authenticatedView/Auth";
import GuetView from "./guestView/Guest";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

import { useRouter } from "next/router";

export const ME = gql`
  query ME {
    me {
      id
      role
      active
      status
      first_name
      last_name
      email
      phone
      TFASettings {
        require2FA
      }
      worker {
        id
        canDo
      }
      employer {
        title
        companyId
        company {
          id
          name
          sName
          ukgCompanyIntegration {
            id
          }
          featureSubscriptions {
            companyFeature {
              sName
            }
          }
        }
      }
    }
  }
`;
type props = {
  action?: any;
  children?: any;
};

const DynamicView: React.FC<props> = (props: any) => {
  const { dispatch } = useAuth();
  const { data: currentUserData, loading } = useQuery(ME, {
    fetchPolicy: "cache-first",
    onCompleted: (data) => {
      dispatch({ type: "USER_DATA", payload: data.me });
    },
  });
  const tawkMessengerRef = useRef<any>();

  const onLoad = () => {
    const info = {
      name: `${currentUserData?.me?.first_name} ${currentUserData?.me?.last_name}`,
      email: currentUserData?.me?.email,
      phone: currentUserData?.me?.phone,
      role: currentUserData?.me?.role,
      "job-title": currentUserData?.me?.employer?.title,
    };
    tawkMessengerRef?.current?.setAttributes(info, function (error: any) {
      console.log(error);
    });
  };
  useEffect(() => {
    dispatch({ type: "AUTH_LOADING", payload: loading });
  }, [loading]);
  const noLayouts = ["/shareLink/[hId]", "/signupCompanyAdmin"];
  const router = useRouter();
  const pathname = router.pathname as string;
  if (loading)
    return (
      <div
        style={{
          width: "100%",
          height: 300,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" className="large-loading absolute-loading" />
      </div>
    );

  if (noLayouts.includes(pathname)) {
    return <>{props.children}</>;
  }

  return (
    <>
      {currentUserData?.me?.role ? (
        <AuthView>{props.children}</AuthView>
      ) : (
        <GuetView>{props.children}</GuetView>
      )}
      <TawkMessengerReact
        propertyId={process.env.NEXT_PUBLIC_TAWKTO_PROPERTY_ID}
        widgetId={process.env.NEXT_PUBLIC_TAWKTO_WIDGET_ID}
        onLoad={onLoad}
        ref={tawkMessengerRef}
      />
    </>
  );
};

export default DynamicView;
