import {
  ApartmentOutlined,
  BankOutlined,
  CalendarOutlined,
  ProfileOutlined,
  UsergroupDeleteOutlined,
  UserOutlined,
  HeatMapOutlined,
  InteractionOutlined,
  RiseOutlined,
  BookOutlined,
  VerticalRightOutlined,
  SettingOutlined,
  AppstoreAddOutlined,
  QrcodeOutlined,
  FundOutlined,
  ClusterOutlined,
} from "@ant-design/icons";
import { useTranslation } from "next-i18next";

export type MenuLink = {
  href: string;
  label: string;
  icon: any;
  children?: MenuLink[];
};

export const SettingsMenu = (t: any): MenuLink[] => {
  // const { t } = useTranslation("layout");
  return [
    {
      href: "/settings/reportType",
      label: t("REPORT_TYPE"),
      icon: <div></div>,
    },
    {
      href: "/settings/notificationType",
      label: t("NOTIFICATION_TYPE"),
      icon: <div></div>,
    },
    {
      href: "/settings/crons",
      label: t("CRONS"),
      icon: <div></div>,
    },
    {
      href: "/settings/shiftTags",
      label: t("SHIFT_TAGS"),
      icon: <div></div>,
    },
  ];
};

export const adminMenuLinks = (t: any): MenuLink[] => {
  // const { t } = useTranslation("layout");
  return [
    {
      href: "/shifts",
      label: t("AVAILABLE_SHIFTS"),
      icon: <CalendarOutlined style={{ fontSize: 24 }} />,
    },
    {
      // href: "/workers?status=ACTIVE",
      href: "/workers",
      label: t("WORKERS"),
      icon: <UsergroupDeleteOutlined style={{ fontSize: 24 }} />,
    },

    {
      href: "/mysites",
      label: t("MY_SITES"),
      icon: <HeatMapOutlined style={{ fontSize: 24 }} />,
    },
    {
      href: "/myprofile",
      label: t("MY_PROFILE"),
      icon: <ProfileOutlined style={{ fontSize: 24 }} />,
    },
    {
      href: "/reports",
      label: t("REPORTS"),
      icon: <RiseOutlined style={{ fontSize: 24 }} />,
      children: [
        {
          href: "/kpis",
          label: t("KPIS"),
          icon: <RiseOutlined style={{ fontSize: 24 }} />,
        },
        {
          href: "/usage-reports",
          label: t("USAGE_REPORTS"),
          icon: <RiseOutlined style={{ fontSize: 24 }} />,
        },
      ],
    },
    // {
    //   href: "/usage-reports",
    //   label: t("USAGE_REPORTS"),
    //   icon: <FundOutlined style={{ fontSize: 24 }} />,
    // },
    {
      href: "/Registration-Link",
      label: t("REFISTRATION_LINK"),
      icon: <QrcodeOutlined style={{ fontSize: 24 }} />,
    },
  ];
};

export const siteAdminLinks = (t: any): MenuLink[] => {
  // const { t } = useTranslation("layout");

  return [
    {
      href: "/shifts",
      label: t("AVAILABLE_SHIFTS"),
      icon: <CalendarOutlined style={{ fontSize: 24 }} />,
    },
    {
      // href: "/workers?status=ACTIVE",
      href: "/workers",
      label: t("WORKERS"),
      icon: <UsergroupDeleteOutlined style={{ fontSize: 24 }} />,
    },
    {
      href: "/admins",
      label: t("EMPLOYERS"),
      icon: <UserOutlined style={{ fontSize: 24 }} />,
    },
    {
      href: "/mysites",
      label: t("MY_SITES"),
      icon: <HeatMapOutlined style={{ fontSize: 24 }} />,
    },
    {
      href: "/myprofile",
      label: t("MY_PROFILE"),
      icon: <ProfileOutlined style={{ fontSize: 24 }} />,
    },
    {
      href: "/reports",
      label: t("REPORTS"),
      icon: <RiseOutlined style={{ fontSize: 24 }} />,
      children: [
        {
          href: "/kpis",
          label: t("KPIS"),
          icon: <RiseOutlined style={{ fontSize: 24 }} />,
        },
        {
          href: "/usage-reports",
          label: t("USAGE_REPORTS"),
          icon: <RiseOutlined style={{ fontSize: 24 }} />,
        },
      ],
    },
    // {
    //   href: "/usage-reports",
    //   label: t("USAGE_REPORTS"),
    //   icon: <FundOutlined style={{ fontSize: 24 }} />,
    // },
    {
      href: "/request",
      label: t("REQUESTS"),
      icon: <AppstoreAddOutlined style={{ fontSize: 24 }} />,
    },
    {
      href: "/Registration-Link",
      label: t("REFISTRATION_LINK"),
      icon: <QrcodeOutlined style={{ fontSize: 24 }} />,
    },
  ];
};

export const companyAdminLinks = (t: any): MenuLink[] => {
  // const { t } = useTranslation("layout");

  return [
    {
      href: "/shifts",
      label: t("AVAILABLE_SHIFTS"),
      icon: <CalendarOutlined style={{ fontSize: 24 }} />,
    },
    {
      // href: "/workers?status=ACTIVE",
      href: "/workers",
      label: t("WORKERS"),
      icon: <UsergroupDeleteOutlined style={{ fontSize: 24 }} />,
    },
    {
      href: "/mycompany",
      label: t("MY_COMPANY"),
      icon: <BankOutlined style={{ fontSize: 24 }} />,
    },
    {
      href: "/admins",
      label: t("EMPLOYERS"),
      icon: <UserOutlined style={{ fontSize: 24 }} />,
    },
    {
      href: "/mysites",
      label: t("MY_SITES"),
      icon: <HeatMapOutlined style={{ fontSize: 24 }} />,
    },
    {
      href: "/clusters",
      label: t("CLUSTERS"),
      icon: <ClusterOutlined style={{ fontSize: 24 }} />,
    },
    {
      href: "/myprofile",
      label: t("MY_PROFILE"),
      icon: <ProfileOutlined style={{ fontSize: 24 }} />,
    },

    {
      href: "/reports",
      label: t("REPORTS"),
      icon: <RiseOutlined style={{ fontSize: 24 }} />,
      children: [
        {
          href: "/kpis",
          label: t("KPIS"),
          icon: <RiseOutlined style={{ fontSize: 24 }} />,
        },
        {
          href: "/usage-reports",
          label: t("USAGE_REPORTS"),
          icon: <RiseOutlined style={{ fontSize: 24 }} />,
        },
      ],
    },
    // {
    //   href: "/usage-reports",
    //   label: t("USAGE_REPORTS"),
    //   icon: <FundOutlined style={{ fontSize: 24 }} />,
    // },
    {
      href: "/request",
      label: t("REQUESTS"),
      icon: <AppstoreAddOutlined style={{ fontSize: 24 }} />,
    },
    {
      href: "/Registration-Link",
      label: t("REGISTRATION_LINK"),
      icon: <QrcodeOutlined style={{ fontSize: 24 }} />,
    },
  ];
};

export const superAdminMenu = (t: any): MenuLink[] => {
  // const { t } = useTranslation("layout");
  // console.log(t("AVAILABLE_SHIFTS"));

  return [
    {
      href: "/shifts",
      label: t("AVAILABLE_SHIFTS"),
      icon: <CalendarOutlined style={{ fontSize: 24 }} />,
    },
    {
      href: "/admins",
      label: t("EMPLOYERS"),
      icon: <UserOutlined style={{ fontSize: 24 }} />,
    },
    {
      href: "/workers",
      label: t("WORKERS"),
      icon: <UsergroupDeleteOutlined style={{ fontSize: 24 }} />,
    },
    {
      href: "/myprofile",
      label: t("MY_PROFILE"),
      icon: <ProfileOutlined style={{ fontSize: 24 }} />,
    },
    {
      href: "/companies",
      label: t("COMPANIES"),
      icon: <BankOutlined style={{ fontSize: 24 }} />,
    },
    {
      href: "/sites",
      label: t("SITES"),
      icon: <ApartmentOutlined style={{ fontSize: 24 }} />,
    },
    {
      href: "/clusters",
      label: t("CLUSTERS"),
      icon: <ClusterOutlined style={{ fontSize: 24 }} />,
    },
    {
      href: "/reports",
      label: t("REPORTS"),
      icon: <RiseOutlined style={{ fontSize: 24 }} />,
      children: [
        {
          href: "/kpis",
          label: t("KPIS"),
          icon: <RiseOutlined style={{ fontSize: 24 }} />,
        },
        {
          href: "/usage-reports",
          label: t("USAGE_REPORTS"),
          icon: <RiseOutlined style={{ fontSize: 24 }} />,
        },
      ],
    },

    // {
    //   href: "/usage-reports",
    //   label: t("USAGE_REPORTS"),
    //   icon: <FundOutlined style={{ fontSize: 24 }} />,
    // },

    {
      href: "/invoicing-kpis",
      label: t("INVIOISING_KPIS"),
      icon: <VerticalRightOutlined style={{ fontSize: 24 }} />,
    },
    {
      href: "/request",
      label: t("REQUESTS"),
      icon: <AppstoreAddOutlined style={{ fontSize: 24 }} />,
    },
    {
      href: "/activity-logs",
      label: t("ACTIVITY_LOGS"),
      icon: <InteractionOutlined style={{ fontSize: 24 }} />,
    },
    {
      href: "/settings",
      label: t("SETTINGS"),
      icon: <SettingOutlined style={{ fontSize: 24 }} />,
      children: [
        {
          href: "/settings/reportType",
          label: t("REPORT_TYPE"),
          icon: <div></div>,
        },
        {
          href: "/settings/companyReportType",
          label: t("COMPANY_REPORT_TYPE"),
          icon: <div></div>,
        },
        {
          href: "/settings/notificationType",
          label: t("NOTIFICATION_TYPE"),
          icon: <div></div>,
        },
        {
          href: "/settings/crons",
          label: t("CRONS"),
          icon: <div></div>,
        },
        {
          href: "/settings/shiftTags",
          label: t("SHIFT_TAGS"),
          icon: <div></div>,
        },
      ],
    },
    {
      href: "/datacollection",
      label: t("DATA_COLLECTION"),
      icon: <BookOutlined style={{ fontSize: 24 }} />,
    },
  ];
};
