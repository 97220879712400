import type { AppProps } from "next/app";
import Page from "../components/layout/Page";
import { ApolloProvider } from "@apollo/client";
import client from "../apollo/apolloClient";
import { appWithTranslation } from "next-i18next";
import nextI18nConfig from "../next-i18next.config";

import "../styles/globals.css";

import { AuthProvider } from "../components/context/authContext";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <GoogleReCaptchaProvider
          reCaptchaKey={`${process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_KEY}`}
          scriptProps={{
            async: false,
            defer: false,
            appendTo: "head",
            nonce: undefined,
          }}
        >
          <Page>
            <Component {...pageProps} />
          </Page>
        </GoogleReCaptchaProvider>
      </AuthProvider>
    </ApolloProvider>
  );
}

export default appWithTranslation(MyApp, nextI18nConfig);
