import { useRouter } from "next/router";
import Link from "next/link";
import Cookie from "js-cookie";
import { US, MX, FR } from "country-flag-icons/react/3x2";
import { Select } from "antd";
export const componentMap: any = {
  en_US: <US style={{ height: "12px" }} />,
  es_MX: <MX style={{ height: "12px" }} />,
  fr_FR: <FR style={{ height: "12px" }} />,
};

export const LanguageSwitcher = ({
  selectedLocale,
  outerOnChangeHandler,
  loading,
}: {
  selectedLocale?: string;
  outerOnChangeHandler?: (value: string) => void;
  loading?: boolean;
}) => {
  const router = useRouter();
  const { locales, locale: activeLocale } = router;

  const otherLocales = locales?.filter((locale) => locale !== activeLocale);
  const changeLocale = (value: string) => {
    // document.cookie = `NEXT_LOCALE=${locale}`;
    // Cookie.remove()
    Cookie.set("NEXT_LOCALE", value, { expires: 365 });
    const { pathname, asPath, query } = router;
    // change just the locale and maintain all other route information including href's query
    router.push({ pathname, query }, asPath, { locale: value });
  };

  return (
    <Select
      size="small"
      value={selectedLocale || activeLocale}
      loading={loading}
      onChange={outerOnChangeHandler || changeLocale}
    >
      {locales?.map((locale: any, localeIndex: number) => {
        return (
          <Select.Option key={locale} label={locale} value={locale}>
            {componentMap[locale]} {""}
          </Select.Option>
        );
      })}
    </Select>
  );
};
