import {
  PlusCircleOutlined,
  CalendarOutlined,
  ProfileOutlined,
  HeatMapOutlined,
  BookOutlined,
} from "@ant-design/icons";

import { MenuLink } from "./adminMenu";

export const workerMenuLinks = (t: any): MenuLink[] => {
  return [
    // { href: '/', label: 'Home', icon: 'home' },
    // { href: "/admin/table", label: "tables", icon: "table" },

    {
      href: "/my-shifts",
      label: t("MY_SHIFTS"),
      icon: <CalendarOutlined style={{ fontSize: 24 }} />,
    },
    {
      href: "/grab-shift",
      label: t("GRAB_SHIFT"),
      icon: <CalendarOutlined style={{ fontSize: 24 }} />,
    },
    {
      href: "/post-shift",
      label: t("POST_A_SHIFT"),
      icon: <PlusCircleOutlined style={{ fontSize: 24 }} />,
    },
    {
      href: "/mysites",
      label: t("MY_SITES"),
      icon: <HeatMapOutlined style={{ fontSize: 24 }} />,
    },
    {
      href: "/myprofile",
      label: t("MY_PROFILE"),
      icon: <ProfileOutlined style={{ fontSize: 24 }} />,
    },
  ];
};
