import { i18n } from "next-i18next";

export const getTitle = (route: any) => {
  const myVar: Record<string, string | undefined> = {
    "/shifts": i18n?.t("layout:SHIFTS"),
    "/shifts?active=": i18n?.t("layout:SHIFTS_SUMMARY"),
    "/shifts?active=calendar": i18n?.t("layout:SHIFTS_CALENDAR"),
    "/shifts?active=Calendar2": i18n?.t("layout:SHIFTS_CALENDAR_SUMMARY"),
    "/admins": i18n?.t("layout:EMPLOYERS"),
    "/workers": i18n?.t("layout:WORKERS"),
    "/myprofile": i18n?.t("layout:MY_PROFILE"),
    "/companies": i18n?.t("layout:COMPANIES"),
    "/sites": i18n?.t("layout:SITES"),
    "/skills": i18n?.t("layout:SKILLS"),
    "/kpis": i18n?.t("layout:KPIS"),
    "/logout": i18n?.t("layout:LOGOUT"),
    "/my-shifts": i18n?.t("layout:MY_SHIFTS"),
    "/my-shifts?active=": i18n?.t("layout:MY_SHIFTS"),
    "/my-shifts?active=calendar": i18n?.t("layout:MY_SHIFTS_CALENDAR"),
    "/my-shifts?active=Calendar2": i18n?.t("layout:MY_SHIFTS_CALENDAR_SUMMARY"),
    "/grab-shift?active=": i18n?.t("layout:GRAB_A_SHIFT"),
    "/grab-shift": i18n?.t("layout:GRAB_A_SHIFT"),
    "/grab-shift?active=calendar": i18n?.t("layout:GRAB_A_SHIFT_CALENDAR"),
    "/post-shift": i18n?.t("layout:POST_A_SHIFT"),
    "/mysites": i18n?.t("layout:MY_SITES"),
    "/mycompany": i18n?.t("layout:MY_COMPANY"),
    "/users": i18n?.t("layout:USERS"),
    "/invoicing-kpis": i18n?.t("layout:INVIOISING_KPIS"),
    "/request": i18n?.t("layout:REQUESTS"),
    "/activity-logs": i18n?.t("layout:ACTIVITY_LOGS"),
    "/usage-reports": i18n?.t("layout:USAGE_REPORTS"),
  };
  return myVar[route] ? myVar[route] : null;
};
