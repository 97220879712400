import { Drawer, Layout, Menu } from "antd";
import Image from "next/image";

import { useEffect, useState } from "react";

const { Header, Sider, Content } = Layout;
import Link from "next/link";

import styles from "./guest.module.scss";
import logoImg from "../../../../public/icons/Hlogo.png";
import sympolLogo from "../../../../public/icons/HapiGig_Symbol_RGB-01.png";
import {
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { useTranslation } from "next-i18next";
import { LanguageSwitcher } from "../authenticatedView/languageSwitcher";

type props = {
  action?: any;
  children?: any;
};

//TODO Fix props type
const AuthView: React.FC<props> = (props: any) => {
  const { t } = useTranslation("signin");

  const [collapsed, setCollapsed] = useState(false);
  const [viewDrawer, setDrawer] = useState(false);
  const [mobileView, setMovileView] = useState(false);

  const [width, setWidth] = useState(window.innerWidth || 0);
  const handleResize = () => setWidth(window.innerWidth);
  useEffect(() => {
    if (window) {
      window.addEventListener("resize", handleResize);

      return () => window.removeEventListener("resize", handleResize);
    }
  }, [window]);

  useEffect(() => {
    width < 600 ? setMovileView(true) : setMovileView(false);
  }, [width]);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout>
      <Layout className="site-layout">
        <Header className="site-layout-header" style={{ padding: 0 }}>
          <div className={styles.header_container}>
            <div className="logo">
              <Image src={sympolLogo} height="60" width="60" alt="logo" />
            </div>

            {mobileView ? (
              <>
                <MenuOutlined
                  onClick={() => setDrawer(true)}
                  type="menu-fold"
                  style={{ fontSize: 18, color: "gray", marginTop: 20 }}
                ></MenuOutlined>
              </>
            ) : (
              <Menu
                theme="light"
                mode="horizontal"
                className={styles.header_menu}
                disabledOverflow
                // defaultSelectedKeys={[location.pathname]}
              >
                {/* <Menu.Item key="/signin">
                  <Link href="/signin" className={styles.nav_link}>
                    {t("SIGN_IN")}
                  </Link>
                </Menu.Item> */}
                <Menu.Item key="lang">
                  <span className="dark">
                    <LanguageSwitcher />
                  </span>
                </Menu.Item>
              </Menu>
            )}
          </div>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            overflow: "auto",
          }}
        >
          {props.children}
          {/* open console =- localStorage.setItem( &quot;userRole&quot;,
          &quot;auth&quot;) =- refresh to see the auth view */}
        </Content>
        <Drawer
          placement="right"
          closable={true}
          onClose={() => setDrawer(false)}
          visible={viewDrawer}
        >
          <Menu
            theme="light"
            mode="vertical"
            style={{ lineHeight: "64px" }}
            onSelect={() => setDrawer(false)}
          >
            <Menu.Item key="/signin">
              <Link href="/signin" className={styles.nav_link_d}>
                {t("SIGN_IN")}
              </Link>
            </Menu.Item>
            <Menu.Item key="lang">
              <LanguageSwitcher />
            </Menu.Item>
          </Menu>
        </Drawer>
      </Layout>
    </Layout>
  );
};

export default AuthView;
