const cleanObj = (obj: any) => {
  for (var propName in obj) {
    if (obj[propName] === "") {
      console.log(propName);
      console.log(obj[propName]);
      delete obj[propName];
    }
  }
  return obj;
};
export default cleanObj;

export const isValidPhoneNumberRegex =
  /^(\+?(1|20)\s*)?\(?\d{3}\)?[\s.,-]*\d{3}[\s.,-]*\d{4}$/;
export const isValidPhoneNumber = (str: string) =>
  isValidPhoneNumberRegex.test(str);

export const isValidZipCodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

export const isValidEmailAddress =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const beforeSwitching = () => {
  const adminToken = localStorage.getItem("token");
  localStorage.setItem("adminToken", adminToken as string);
};

export const cronExpression =
  /^(\*|\d{1,2}|\d{1,2}-\d{1,2})(\/\d{1,2})?\s+(\*|\d{1,2}|\d{1,2}-\d{1,2})(\/\d{1,2})?\s+(\*|\d{1,2}|\d{1,2}-\d{1,2})(\/\d{1,2})?\s+(\*|\d{1,2}|\d{1,2}-\d{1,2})(\/\d{1,2})?\s+(\*|\d{1,2}|\d{1,2}-\d{1,2})(\/\d{1,2})?$/;

export const isInteger = (item: number) => {
  return Number.isInteger(item);
};

export const convertString = (inputString: string) => {
  var words = inputString.split("_");
  var capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  var outputString = capitalizedWords.join(" ");
  return outputString;
};
