import React, { useContext, useEffect } from "react";
import { useRouter } from "next/router";
import Router from "next/router";
import { Button, Modal, notification as antNotification } from "antd";
import Head from "next/head";
import DynamicView from "./dynamicViwe/DynamicView";

// Router.onRouteChangeStart = () => {
//   NProgress.start();
// };
// Router.onRouteChangeComplete = () => {
//   NProgress.done();
// };

// Router.onRouteChangeError = () => {
//   NProgress.done();
// };

//TODO: Fix any type of page
const Page = (props: any) => {
  //   const isBrowser = typeof window !== "undefined";

  return (
    <>
      <Head>
        <title>TheShiftSwap</title>
        {/* <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" /> */}
        <meta name="theme-color" content="#ffffff" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
        />
      </Head>
      <DynamicView>{props.children} </DynamicView>
    </>
  );
};

export default Page;
