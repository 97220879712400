import * as React from "react";

type Action = { type: string; payload?: any };
type AuthProviderProps = { children: React.ReactNode };
type Dispatch = (action: Action) => void;

////https://kentcdodds.com/blog/how-to-use-react-context-effectively

const AuthContext = React.createContext<
  { state: any; dispatch: Dispatch } | undefined
>(undefined);

function AuthReducer(state: any, action: Action) {
  switch (action.type) {
    case "LOGIN": {
      localStorage.setItem("token", action.payload);
      return { ...state };
    }
    case "SENTRY_DATA": {
      localStorage.setItem("email", action.payload);
      return { ...state };
    }
    case "USER_DATA": {
      return {
        ...state,
        userData: action.payload,
        clusterAllowed:
          action.payload?.role === "SUPER_ADMIN" ||
          action.payload?.employer?.company?.featureSubscriptions?.some(
            (feature: { companyFeature: { sName: string } }) =>
              feature.companyFeature.sName === "CLUSTERS"
          ),
      };
    }
    case "AUTH_LOADING": {
      return { ...state, authLoading: action.payload };
    }
    case "LOGOUT": {
      return { ...state, userData: undefined };
    }
    case "TITLE": {
      return { ...state, title: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = React.useReducer(AuthReducer, { userData: {} });
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }

  return context;
}

export { AuthProvider, useAuth };
